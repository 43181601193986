import { AllLocales } from '../constants/i18n';

export enum WpPostTypes {
  CustomerSpotlight = 'customerSpotlight',
  Page = 'page',
  Partial = 'partial',
  Recipe = 'recipe',
  Reusable = 'reusable'
}

export type WpBlockI18nLocaleContent = {
  source: Record<string, string>;
  translation: Record<string, string>;
};

export type WpBlockI18n = {
  [key in AllLocales]?: WpBlockI18nLocaleContent;
};

export type WpBlock = {
  attributes: {
    [key: string]: unknown;
    i18n?: WpBlockI18n;
    id?: string;
  };
  innerBlocks?: WpBlock[];
  name: string;
};

export type WpPostMeta = { [key: string]: unknown; } & {
  attributes: {
    [key: string]: unknown;
    i18n?: WpBlockI18n;
    id?: string;
  };
  global: {
    _locales: AllLocales[];
    _localesPublished: AllLocales[];
    _localesToTranslate: AllLocales[];
  };
  id: string;
  page: {
    _amplitudePageType: string;
    _headerOptions: unknown;
  };
  seo: {
    _isIndexed: unknown[];
    title: string;
  };
};

export type WpPostWp = {
  author: string;
  categories: string[];
  date: string;
  excerpt: string;
  isSticky: boolean;
  slug: string;
  status: string;
  title: string;
  type: 'customer_spotlight' | 'page' | 'partial' | 'recipe';
};

export type WpPost = {
  blocks: WpBlock[];
  customerSpotlightId?: number;
  data: unknown;
  meta: WpPostMeta;
  pageId?: number;
  partialId?: number;
  partials: unknown;
  recipeId?: number;
  wp: WpPostWp;
};

export type WpPostKeys = keyof WpPost;
